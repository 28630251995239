<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Пользователи: Администраторы</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0" v-if="isNotCreated">{{ user.first_name }} {{ user.last_name }}</h5>
          <h5 class="mb-0" v-else>Новый администратор</h5>
        </div>
      </div>
      <div class="card-body">
        <a-form-model ref="form" :model="user">
          <a-form-model-item class="text_area" label="Аватар">
            <a-upload
              :disabled="isPreview || isNotCreated"
              list-type="picture-card"
              :file-list="fileListMain"
              @change="handleChangeList"
            >
              <div v-if="fileListMain.length < 1 && !isPreview && !isNotCreated">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  Upload
                </div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="Имя" required>
            <a-input v-model="user.first_name" :disabled="isPreview" />
          </a-form-model-item>
          <a-form-model-item label="Фамилия" required>
            <a-input v-model="user.last_name" :disabled="isPreview" />
          </a-form-model-item>
          <a-form-model-item label="Телефон" required>
            <a-input v-model="user.phone" :disabled="isPreview" />
          </a-form-model-item>
          <a-form-model-item label="Email" required>
            <a-input v-model="user.email" :disabled="isPreview" />
          </a-form-model-item>
          <a-form-model-item label="Роль" required>
            <a-select v-model="user.role" :disabled="isPreview" placeholder="выберите статус">
              <a-select-option v-for="type in roles" :key="type.id" :value="type.name">
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Активность" required>
            <a-select v-model="user.is_blocked" :disabled="isPreview" placeholder="выберите значение">
              <a-select-option v-for="type in permissions" :key="type.id" :value="type.id">
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Пароль" :required="!isNotCreated">
            <a-input v-model="user.password" :disabled="isPreview" />
          </a-form-model-item>
          <a-form-model-item label="Подтверждение пароля" :required="!isNotCreated">
            <a-input v-model="user.password_confirmation" :disabled="isPreview" />
            <span v-if="isPasswordNotConfirmed" class="error_message">Пароли не совпадают</span>
          </a-form-model-item>
        </a-form-model>
        <a-button v-if="!isPreview" class="ml-auto d-block" type="primary" @click="submit" :disabled="isDisabled">
          {{ this.$route.params.id !== '0' ? 'Сохранить изменения' : 'Добавить'}}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import users from '@/mixins/users'
import permissions from '@/entities/permissions'

export default {
  name: 'AdminPage',
  mixins: [users],
  data() {
    return {
      fileListMain: [],
      roles: [],
      permissions,
      user: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        is_blocked: '',
        role: '',
        password: '',
        password_confirmation: '',
      },
    }
  },
  computed: {
    isDisabled() {
      const user = this.user
      if (this.isNotCreated) {
        let isDisabled = false
        if (!(user.first_name && user.last_name && user.phone && user.email && `${user.is_blocked}` && user.role)) {
          isDisabled = true
        }

        if (user.password && user.password_confirmation && user.password !== user.password_confirmation) {
          isDisabled = true
        }

        return isDisabled
      } else {
        return !(user.first_name &&
          user.last_name &&
          user.phone &&
          user.email &&
          `${user.is_blocked}` &&
          user.role &&
          user.password &&
          user.password_confirmation &&
          user.password === user.password_confirmation)
      }
    },
    isPasswordNotConfirmed() {
      const user = this.user
      return user.password && user.password_confirmation && user.password !== user.password_confirmation
    },
  },
  async created() {
    if (this.isNotCreated) {
      await Promise.all([this.getUserInfo(), this.getRoles()])
    } else {
      await this.getRoles()
    }
  },
  methods: {
    async getUserInfo() {
      try {
        this.user = (await this.$services.get(`admin/managers/${this.$route.params.id}`)).data.data.user

        if (this.user.image) {
          this.fileListMain.push({
            url: this.user.image,
            uid: '-4',
            name: 'image.png',
            status: 'done',
          })
        }

        console.log('user', this.user)
      } catch (e) {
        console.log(e)
      }
    },
    async getRoles() {
      try {
        this.roles = (await this.$services.get('admin/roles')).data.data.roles
      } catch (e) {
        console.log(e)
      }
    },
    async submit() {
      if (this.fileListMain[0] && this.fileListMain[0].thumbUrl) this.user.icon = this.fileListMain[0].thumbUrl

      if (this.isNotCreated && !this.user.password && !this.user.password_confirmation) {
        delete this.user.password
        delete this.user.password_confirmation
      }

      try {
        this.$route.params.id === '0' ? await this.$services.post('admin/managers', this.user) : await this.$services.put(`admin/managers/${this.user.id}`, this.user)
        this.$router.back()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
  .error_message {
    font-size: 12px;
    color: #fa0303;
  }
}
</style>
